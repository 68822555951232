/**
 * Created by mac on 17/10/19.
 */
import http from '../common/Request'
import Timeutils from '../common/TimeUtil'
import ConfigUtil from '../common/Config'
import uni from '@/services/UNIService'
const CouponService = {
  async doPageCoupon (groupId, status, userType, grantType, couponType) { // 获取商户的券
    var res = await http({
      url: 'coupon_doPage.do',
      method: 'POST',
      data: {
        groupId: groupId,
        status: status,
        userType: userType,
        grantType: grantType,
        couponType: couponType
      }
    })
    return res.data
  },
  async doDrawCoupon (couponId) { // 领取券
    var res = await http({
      url: 'coupon_doDrawCoupon.do',
      method: 'POST',
      loading:"领取载入...",
      data: {
        couponId: couponId
      }
    })
    return res.data
  },
  async doGetCouponItemDetailsByCouponItemId (couponItemId) { // 根据couponItemId查询用户的优惠券
    var res = await http({
      url: 'coupon_doGetCouponItemDetailsByCouponItemId.do',
      method: 'POST',
      data: {
        couponItemId: couponItemId,
      }
    })
    return res.data
  },
  async doGetCouponDetails (couponId) { // 根据couponId查询商户的优惠券
    var res = await http({
      url: 'openCoupon_doGetCoupon.do',
      method: 'POST',
      data: {
        couponId: couponId,
      }
    })
    return res.data.data
  },
  async doPageCouponItem (ptradingAreaId, status, couponType, page ,count) { // 根据券id查询用户的优惠券
    var res = await http({
      url: 'coupon_doPageCouponItem.do',
      method: 'POST',
      data: {
        ptradingAreaId: ptradingAreaId,
        status: status,
        deleteStatus: 0,
        couponType:couponType,
        page:page,
        count:count
      }
    })
    return res.data
  },
  doGetCouponModel (coupon) { // 根据商户券的coupon对象封装需要显示的样式
    var tempCoupon = {id: '', couponItems:[],color: '', logoImage:'', receivedIdentImg:'',openWeChatCouponId:'',couponName: '', couponDes: '', couponStyle: '', couponCondition: '', receiveStatus: 0, receiveDes: ''}
    switch (coupon.couponType) {
      case 1: { // 代金券
        tempCoupon.id = coupon.id
        tempCoupon.couponItems=coupon.couponItems
        tempCoupon.logoImage = 'https://www.suconnected.com/xcx/assets/superMechanics/coupon_type1.jpg'
        tempCoupon.openWeChatCouponId = coupon.openWeChatCouponId
        tempCoupon.color = coupon.color
        tempCoupon.couponName = coupon.name
        tempCoupon.couponDes = coupon.couponDesc
        tempCoupon.couponStyle = '￥' + coupon.reducePrice
        tempCoupon.coverSummary = coupon.coverSummary
        if (coupon.meetPrice > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetPrice + '元可用'
        } else if (coupon.meetCount > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetCount + '件可用'
        }
        tempCoupon.receiveStatus = coupon.isReceives
        if (tempCoupon.receiveStatus === 0) {
          tempCoupon.receiveDes = '点击领取'
        } else if (coupon.isReceives === 1) {
          tempCoupon.receiveDes = '去使用'
          tempCoupon.receivedIdentImg= 'https://www.suconnected.com/xcx/assets/superMechanics/received.png'
        } else { // 已领取未到使用时间
          tempCoupon.receiveDes = '暂不可用'
        }

        break
      }
      case 3: { // 计次券
        tempCoupon.id = coupon.id
        tempCoupon.couponItems=coupon.couponItems
        tempCoupon.logoImage = 'https://www.suconnected.com/xcx/assets/superMechanics/coupon_type3.jpg'
        tempCoupon.openWeChatCouponId = coupon.openWeChatCouponId
        tempCoupon.color = coupon.color
        tempCoupon.couponName = coupon.name
        tempCoupon.couponDes = coupon.couponDesc
        tempCoupon.couponStyle = coupon.useCount + '次'
        tempCoupon.coverSummary = coupon.coverSummary
        if (coupon.meetPrice > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetPrice + '元可用'
        } else if (coupon.meetCount > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetCount + '件可用'
        }
        tempCoupon.receiveStatus = coupon.isReceives
        if (tempCoupon.receiveStatus === 0) {
          tempCoupon.receiveDes = '点击领取'
        } else if (coupon.isReceives === 1) {
          tempCoupon.receiveDes = '去使用'
          tempCoupon.receivedIdentImg= 'https://www.suconnected.com/xcx/assets/superMechanics/received.png'
        } else { // 已领取未到使用时间
          tempCoupon.receiveDes = '暂不可用'
        }

        break
      }
      case 4: { // 结伴券
        tempCoupon.id = coupon.id
        tempCoupon.couponItems=coupon.couponItems
        tempCoupon.logoImage = 'https://www.suconnected.com/xcx/assets/superMechanics/coupon_type4.jpg'
        tempCoupon.openWeChatCouponId = coupon.openWeChatCouponId
        tempCoupon.color = coupon.color
        tempCoupon.couponName = coupon.name
        tempCoupon.couponDes = coupon.couponDesc
        tempCoupon.couponStyle = coupon.companyMembers + '人'
        tempCoupon.coverSummary = coupon.coverSummary
        if (coupon.meetPrice > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetPrice + '元可用'
        } else if (coupon.meetCount > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetCount + '件可用'
        }
        tempCoupon.receiveStatus = coupon.isReceives
        if (tempCoupon.receiveStatus === 0) {
          tempCoupon.receiveDes = '点击领取'
        } else if (coupon.isReceives === 1) {
          tempCoupon.receiveDes = '去使用'
          tempCoupon.receivedIdentImg= 'https://www.suconnected.com/xcx/assets/superMechanics/received.png'
        } else { // 已领取未到使用时间
          tempCoupon.receiveDes = '暂不可用'
        }

        break
      }
      case 5: { // 折扣券
        tempCoupon.id = coupon.id
        tempCoupon.couponItems=coupon.couponItems
        tempCoupon.logoImage = 'https://www.suconnected.com/xcx/assets/superMechanics/coupon_type5.jpg'
        tempCoupon.openWeChatCouponId = coupon.openWeChatCouponId
        tempCoupon.color = coupon.color
        tempCoupon.couponName = coupon.name
        tempCoupon.couponDes = coupon.couponDesc
        tempCoupon.couponStyle = coupon.discount + '折'
        tempCoupon.coverSummary = coupon.coverSummary
        if (coupon.meetPrice > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetPrice + '元可用'
        } else if (coupon.meetCount > 0) {
          tempCoupon.couponCondition = '满' + coupon.meetCount + '件可用'
        }
        tempCoupon.receiveStatus = coupon.isReceives
        if (tempCoupon.receiveStatus === 0) {
          tempCoupon.receiveDes = '点击领取'
        } else if (coupon.isReceives === 1) {
          tempCoupon.receiveDes = '去使用'
          tempCoupon.receivedIdentImg= 'https://www.suconnected.com/xcx/assets/superMechanics/received.png'
        } else { // 已领取未到使用时间
          tempCoupon.receiveDes = '暂不可用'
        }

        break
      }
      case 8: { //报销券
        tempCoupon.id = coupon.id
        tempCoupon.couponItems=coupon.couponItems
        tempCoupon.logoImage = 'https://www.suconnected.com/xcx/assets/superMechanics/coupon_type1.jpg'
        tempCoupon.openWeChatCouponId = coupon.openWeChatCouponId
        tempCoupon.color = coupon.color
        tempCoupon.couponName = coupon.name
        tempCoupon.couponDes = coupon.couponDesc
        tempCoupon.couponStyle = '￥' + coupon.reducePrice
        tempCoupon.couponCondition = '消费' + coupon.meetPrice + '元可用'
        tempCoupon.receiveStatus = coupon.isReceives
        tempCoupon.reducePrice = coupon.reducePrice
        tempCoupon.coverSummary = coupon.coverSummary
        tempCoupon.pairStartDate = coupon.pairStartDate
        tempCoupon.pairEndDate = coupon.pairEndDate
        if (tempCoupon.receiveStatus === 0) {
          tempCoupon.receiveDes = '点击领取'
        } else if (coupon.isReceives === 1) {
          tempCoupon.receiveDes = '去使用'
          tempCoupon.receivedIdentImg= 'https://www.suconnected.com/xcx/assets/superMechanics/received.png'
        } else { // 已领取未到使用时间
          tempCoupon.receiveDes = '暂不可用'
        }

        break
      }
      default:
    }
    return tempCoupon
  },
  doGetCouponItemModel (couponItem) { // 根据用户券的couponItem对象封装需要显示的样式
    var currentTime = new Date().getTime();
    if (couponItem.status == 4) { //未使用
      couponItem.isNew = (currentTime - couponItem.startDate) / (1000 * 60 * 60 * 24) < 3 // 判断是不是新到的券
    } else if (couponItem.status == 1) { //已使用
      couponItem.receivedIdentImg = 'https://www.suconnected.com/xcx/assets/superMechanics/used.png'
    } else if (couponItem.status == 2) { //绑定中
      couponItem.receivedIdentImg = 'https://www.suconnected.com/xcx/assets/superMechanics/bind.png'
    } else if (couponItem.status == 3) { //已过期
      couponItem.receivedIdentImg = 'https://www.suconnected.com/xcx/assets/superMechanics/overdue.png'
    } else { //暂不可用
      couponItem.receivedIdentImg = 'https://www.suconnected.com/xcx/assets/superMechanics/unavailable.png'
      couponItem.isNew = (currentTime - couponItem.startDate) / (1000 * 60 * 60 * 24) < 3 // 判断是不是新到的券
    }
    couponItem.startTime = Timeutils.formatTime(couponItem.startDate, 'Y.M.D')
    couponItem.endTime = Timeutils.formatTime(couponItem.endDate, 'Y.M.D')
    switch(couponItem.couponType) {
      case 1: //代金券
        couponItem.nameType = '代金券';
        couponItem.couponName = '￥' + couponItem.reducePrice;
        if (couponItem.meetPrice && couponItem.meetPrice > 0) {
          couponItem.couponCondition = '满' + couponItem.meetPrice + '元可用';
        } else if (couponItem.meetCount && couponItem.meetCount > 0) {
          couponItem.couponCondition = '满' + couponItem.meetCount + '件可用';
        }
        break;
      case 3: //计次券
        couponItem.nameType = '计次券';
        couponItem.couponName = (couponItem.count-couponItem.usedCount)+'次';
        couponItem.couponCondition = (couponItem.cycleCount==1?'每':couponItem.cycleCount)+(couponItem.cycleType==1?'年':(couponItem.cycleType==2?'月':'天'))+'使用'+couponItem.cycleTimes+'次';
        break;
      case 4: //结伴券
        couponItem.nameType = '结伴券';
        couponItem.couponName = couponItem.companyMembers + '人'
        if (couponItem.meetPrice > 0) {
          couponItem.couponCondition = '满' + couponItem.meetPrice + '元可用';
        } else if (couponItem.meetCount > 0) {
          couponItem.couponCondition = '满' + couponItem.meetCount + '件可用';
        }
        break;
      case 5: //折扣券
        couponItem.nameType = '折扣券';
        couponItem.couponName = couponItem.discount + '折';
        if (couponItem.meetPrice > 0) {
          couponItem.couponCondition = '满' + couponItem.meetPrice + '元可用'
        } else if (couponItem.meetCount > 0) {
          couponItem.couponCondition = '满' + couponItem.meetCount + '件可用'
        }
        break;
      case 8: //报销券
        couponItem.nameType = '报销券';
        couponItem.couponName = '￥' + couponItem.reducePrice;
        couponItem.couponCondition = '消费' + couponItem.meetPrice + '元可用';
        break;
      default:
    }
    return couponItem
  },
  async doGetCardSign(cardId, code){
    var res = await http({
      url: 'openWeixin_doGetCardSign.do',
      method: 'POST',
      data: {
        cardId: cardId,
        appId: ConfigUtil.getAppId(),
        code:code
      }
    });

    return res;
  },
  async doAddWXCardList(couponItems,callback) { // 批量添加到多张微信卡券
    var cardList = [];
    for (var i=0;i<couponItems.length;i++){
      var  couponItem = couponItems[i];
      var res =await CouponService.doGetCardSign(couponItem.openWeChatCouponId,  couponItem.couponNum);
      if (res.data.status == 1){
        cardList.push({
          cardId:res.data.data.cardId,
          cardExt: '{"code":'+couponItem.couponNum+',"timestamp":'+res.data.data.timestamp+',"signature":"'+res.data.data.signature+'","nonce_str":"'+res.data.data.nonceStr+'"}'
        });
      }
    }
    console.log('cardList=')
    console.log(cardList);
    if (cardList.length >0){
      wx.addCard({
        cardList: cardList,
        success: function(res) {
          console.log('addCard success')
          callback({isDrawWeChatCardPack:1})
        },fail:function (res) {
          console.log('addCard fail')
          callback({isDrawWeChatCardPack:0})
        }
      })
    }
  },
  async doAddWXCard (openWeChatCouponId,couponItem,callback) { // 添加到单张微信卡券
    console.log("AddWXCard--------"+openWeChatCouponId+',' + couponItem.couponNum)
    var response = await CouponService.doGetCardSign(openWeChatCouponId,  couponItem.couponNum);
    if (response.data.status === 1) {
      var res= response.data.data;
      var cardList = [{
        cardId:res.cardId,
        cardExt: '{"code": '+couponItem.couponNum+',  "timestamp": '+res.timestamp+', "signature":"'+res.signature+'",  "nonce_str":"'+res.nonceStr+'"}'
      }]
      wx.addCard({
        cardList: cardList,
        success: function(res) {
          console.log('add success!')
          callback({isDrawWeChatCardPack:1})
        },
        fail:function (res) {
          console.log('add failed!')
          callback({isDrawWeChatCardPack:0})
        }
      })
    }
  },
  async doOpenWXCard (couponItem,callback) { // 打开微信卡券包
    console.log("OpenWXCard------"+couponItem.openWeChatCouponId+',' + couponItem.couponNum)
    var response = await CouponService.doGetCardSign(couponItem.openWeChatCouponId,  couponItem.couponNum);
    if (response.data.status === 1) {
      var res= response.data.data;
      console.log(res)
      var cardList = [{
        cardId:res.cardId,
        code: couponItem.couponNum
      }]
      wx.openCard({
        cardList: cardList,
        success: function(res) {
          callback(res)
        },
        fail:function (res) {
          callback(res)
        }
      })
    }
  },
  async doPageCouponItemNew(couponItem){
    var res = await http({
      url: 'coupon_doPageUserCouponItem.do',
      method: 'POST',
      data: {couponItem:couponItem}
    });

    return res.data;
  },
  async doAccelerateReimburse(couponItemIds) { //加速报销
    var res = await http({
      url: 'marketingScene_doAccelerateReimburse.do',
      method: 'POST',
      data: {couponItemIds:couponItemIds}
    });

    return res.data;
  },
  async doGetCouponItemCount(couponType,status,userId,ptradingAreaId){ //获取我的报销券数量
    var res = await http({
      url: 'coupon_doGetCouponItemCount.do',
      method: 'POST',
      data: {couponItem:{couponType:couponType,status:status,userId:userId,ptradingAreaId:ptradingAreaId,deleteStatus:0}}
    });

    return res.data;
  },
  async doSearchRankingCoupon(userId,ptradingAreaId){ //平台有排名中的券
    var res = await http({
      url: 'coupon_doSearchRankingCoupon.do',
      method: 'POST',
      data: {userId:userId,ptradingAreaId:ptradingAreaId}
    });
    return res.data;
  },
  async doPageUserCouponItem(couponType,sortStatus,status,userId,reimbursementStatus,ptradingAreaId,page,count){ //用户所有等候中的券
    var res = await http({
      url: 'coupon_doPageUserCouponItem.do',
      method: 'POST',
      data: {couponItem:{couponType:couponType,sortStatus:sortStatus,status:status,userId:userId,reimbursementStatus:reimbursementStatus,ptradingAreaId:ptradingAreaId},page:page,count:count}
    });
    return res.data;
  },
  async doSearchUnderwayReimburseCouponItems(couponItemId){ //用户所有等候中的券
    var res = await http({
      url: 'coupon_doSearchUnderwayReimburseCouponItems.do',
      method: 'POST',
      data: {couponItemId:couponItemId}
    });
    return res.data;
  },
  async doGetExchangeAmount (ptradingAreaId, userId,groupId) { // 获取平台的异业兑换金额
    var res = await http({
      url: 'coupon_doGetExchangeAmount.do',
      method: 'POST',
      data: {ptradingAreaId: ptradingAreaId,userId: userId,groupId:groupId}
    })
    return res.data
  },
  async doGetAccount(groupId,moduleId,modelId,name) { // 获取账户积分余额
    var res = await http({
      url: "commonParam_doGet.do",
      method: "POST",
      data: {groupId:groupId,moduleId:moduleId,modelId: modelId,name:name}
    });
    return res.data;
  },
  async doExchangeAmount(exchangeAmount, groupId) { // 确认兑换金额
    var res = await http({
      url: 'coupon_doExchangeAmount.do',
      method: 'POST',
      data: {exchangeAmount: exchangeAmount,groupId: groupId}
    })
    return res.data
  },
  async doSearchUserGroups (userId,isShowGoods) { //异业兑换店铺
    var res = await http({
      url: 'coupon_doSearchUserGroups.do',
      method: 'POST',
      data: {userId:userId,isShowGoods:isShowGoods}
    })
    return res.data
  },
  async doPageCouponLog (userId,couponType,ptradingAreaId,logDate,endLogDate,platformType,logType,page,count,couponId,toUserId) { //异业兑换日志
    var res = await http({
      url: 'coupon_doPageCouponLog.do',
      method: 'POST',
      data: {couponLog:{userId:userId,couponType:couponType,ptradingAreaId:ptradingAreaId,logDate:logDate,endLogDate:endLogDate,platformType:platformType,logType:logType,couponId:couponId,toUserId:toUserId },page:page,count:count}
    })
    return res.data
  },
  async doGetAccessAndUseExchangeAmount (userId,couponType,ptradingAreaId,logDate,endLogDate,platformType) { //异业兑换店铺
    var res = await http({
      url: 'coupon_doGetAccessAndUseExchangeAmount.do',
      method: 'POST',
      data: {couponLog:{userId:userId,couponType:couponType,ptradingAreaId:ptradingAreaId,logDate:logDate,endLogDate:endLogDate,platformType:platformType}}
    })
    return res.data
  },
  async doGetUserCouponResidualCount (userId,couponId) { //异业兑换店铺
    var res = await http({
      url: 'coupon_doGetUserCouponResidualCount.do',
      method: 'POST',
      data: {userId:userId,couponId:couponId}
    })
    return res.data
  },
  async doBatchConsumeCouponItems (couponItemIds) { //批量核销券
    var res = await http({
      url: "coupon_doBatchConsumeCouponItems.do",
      data: {id:couponItemIds},
      bindToken:true
    });
    return res.data
  },
  async doGetCouponItemByCouponNumForUser(couponNum) { //根据券码查询券
    var res = await http({
      url: "coupon_doGetCouponItemByCouponNumForUser.do",
      data: {couponNum:couponNum}
    });
    return res.data
  },
  async doGetCouponItemByCouponNum(couponNum) { //根据券码查询券
    var res = await http({
      url: "coupon_doGetCouponItemByCouponNum.do",
      data: {couponNum:couponNum},
      bindToken:true
    });
    return res.data
  },
  async doSearchUseCanConsumeCouponItems (groupId,couponType,status,reimbursementStatus,userId){ //查询用户可核销的券（可提现 可兑换的券
    var res = await http({
      url: "coupon_doSearchUseCanConsumeCouponItems.do",
      data: {couponItem:{groupId:groupId,couponType:couponType,status:status,reimbursementStatus:reimbursementStatus,userId:userId}},
      bindToken:true
    });
    return res.data
  },
  async doStatisticCouponLog(sm, searchModel, page, count) { //获取订单
    var res = await http({
      url: "coupon_doStatisticCouponLog.do",
      data: {
        searchModel: searchModel,
        sm:sm,
        count: count,
        page: page
      },
      bindToken: true
    })
    return res.data
  },
  async doStatisticGroupByCoupon(sm, searchModel, page, count) { //获取订单
    var res = await http({
      url: "coupon_doStatisticGroupByCoupon.do",
      data: {
        searchModel: searchModel,
        sm:sm,
        count: count,
        page: page
      },
      bindToken: true
    })
    return res.data
  },
  async doSearchBrotherCoupons(brotherId) { //异业兑换日志
    var res = await http({
      url: 'coupon_doSearchBrotherCoupons.do',
      method: 'POST',
      data: {brotherId:brotherId}
    })
    return res.data.data
  },
  async doBrotherChange(couponItemId,toCouponId) { //异业兑换日志
    var res = await http({
      url: 'coupon_doBrotherChange.do',
      method: 'POST',
      data: {couponItemId:couponItemId,toCouponId:toCouponId}
    })
    return res.data.data
  },
  async doPageCouponLog(searcModel, page, count) { //异业兑换日志
    var res = await http({
      url: 'coupon_doPageCouponLog.do',
      method: 'POST',
      data: {couponLog:searcModel,page:page,count:count}
    })
    return res.data
  }
}
export default CouponService
